/* UserDash.css */

/* General Styling */
body {
  font-family: "Arial", sans-serif;
  background-color: #f5f5f5;
}

.container {
  margin-top: 20px;
}

.user-dash-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Candidate Card Styling */
.candidate-card {
  background: linear-gradient(135deg, #6dd5ed, #2193b0);
  color: white;
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

.candidate-card .card-header {
  background: rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
  font-weight: bold;
}

.candidate-info-card {
  background-color: white;
  color: #333;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.candidate-info-card .card-body {
  padding: 20px;
}

.candidate-name {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2193b0;
}

.candidate-email {
  font-size: 1.2rem;
  color: #6dd5ed;
  margin-bottom: 15px;
}

.candidate-details {
  list-style-type: none;
  padding: 0;
  line-height: 1.6;
}

/* Companies Card Styling */
.companies-card {
  background-color: white;
  color: #333;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.companies-card .card-header {
  /* background-color: #2193b0; */
  /* color: white; */
  font-size: 1.5rem;
  font-weight: bold;
}

/* Scrollable Table */
.table-responsive {

  overflow-y: auto;
  margin-top: 20px;
}

.company-table th,
.company-table td {
  vertical-align: middle;
  text-align: left;
}

.company-table th {
  background-color: #2193b0;
  color: white;
}

.company-table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Loading Styling */
.loading {
  text-align: center;
  font-size: 1.5rem;
  color: #2193b0;
}
@media screen and (max-width: 768px) {
  .col-8 {
    width: auto;
  }
  .companies-card{
    width: 155%;
  }
  .doted{
    margin-bottom: 10px;
  }
}
