.header_section1 .container-fluid1 {
    padding-right: 25px;
    padding-left: 25px;
  }
  
  .header_section1 .header_top1 {
    padding: 15px 0;
    background-image: linear-gradient(to right, #009efb 0%, #5439fb 50%, #150aed 100%);
  }
  
  .header-span {
    color: white;
    text-decoration: none;
  }
  
  .header_section1 .header_top1 .contact_nav1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .header_section1 .header_bottom1 {
    padding: 10px 0;
  }
  
  @media (max-width: 767px) {
    .header_section1 .container-fluid1 {
      padding-right: 15px;
      padding-left: 15px;
    }
  
    .header_section1 .header_top1 {
      padding: 10px 0;
    }
  
    .header_section1 .header_top1 .contact_nav1 {
      flex-wrap: wrap;
    }
  }
  
  @media (max-width: 200px) {
    .header_section1 .container-fluid1 {
      padding-right: 10px;
      padding-left: 10px;
    }
  
    .header_section1 .header_top1 {
      padding: 10px 0;
    }
  
    .header-span {
      font-size: 25px;
    }
  
    .header_section1 .header_top1 .contact_nav1 {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      flex-wrap: wrap;
    }
  
    .header_section1 .header_bottom1 {
      padding: 5px 0;
    }
  }
  