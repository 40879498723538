.form-group label{
    font-weight: 600;
}
.form-check label{
    font-weight: unset;
}


.button-with-custom-loader {
    position: relative;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #0041f9; /* Button color */
    color: #fff; /* Text color */
    border: none;
    border-radius: 5px;
    overflow: hidden; /* To clip the loader */
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
