@media screen and (max-width: 768px) {
  .main-content {
    margin-left: 0;
  }

  .table thead {
    display: none;
  }

  .table tbody tr {
    display: block;
    margin-bottom: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .table tbody tr td {
    /* display: flex; */
    justify-content: space-between;
    padding: 10px;
    /* border: none; */
    border-bottom: 1px solid #ddd;
  }

  .table tbody tr td:last-child {
    border-bottom: 0;
  }

  .table tbody tr td:before {
    content: attr(data-label);
    font-weight: bold;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 72.333333%;
  }

  /* .btn {
        width: 100%;
        margin-top: 10px;
    } */
}
@media screen and (max-width: 765px) {
  .col-md-10 {
    /* flex: 0 0 auto; */
    width: 100%;
  }
}
